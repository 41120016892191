.shoe-size-button {
	width: 20px !important;
	margin-right: 20px !important;
	margin-bottom: 20px !important;
	background-color: rgb(222, 222, 222) !important;
	color: var(--secondary) !important;
	font-family: inherit !important;
	font-weight: bold !important;
	letter-spacing: 1px !important;
}

.age-range-button {
	width: 100% !important;
	margin-left: 10% !important;
	margin-right: 10% !important;
	display: flex !important;
	text-transform: none !important;
	background-color: rgb(222, 222, 222) !important;
	color: var(--secondary) !important;
	font-family: inherit !important;
	font-weight: bold !important;
	letter-spacing: 1px !important;
}

.active {
	background-color: var(--primary-green) !important;
	color: var(--secondary) !important;
}
