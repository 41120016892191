.image-container {
	display: flex;
	justify-content: center;
	align-items: center;
	// border: 1px solid red;
}
.left-foot-profile .profile-img {
	height: 28vw;
	width: auto;
	object-fit: contain;
}

.pain-point-item-selected-profile {
	background-color: red !important;
	box-shadow: 0 0 20px 3px red !important;
}

.left-foot-profile .profile-3 {
	top: 50%;
	left: 15%;
}

.left-foot-profile .profile-2 {
	top: 50%;
	left: 33%;
}

.left-foot-profile .profile-1 {
	top: 75%;
	left: 43%;
}

.left-foot-profile .profile-4 {
	top: 20%;
	left: 16%;
}

.left-foot-profile .profile-7 {
	top: 12%;
	left: 33%;
}

.left-foot-profile .profile-8 {
	top: 38%;
	left: 48%;
}

.left-foot-profile .profile-6 {
	top: 74%;
	left: 59%;
}

.left-foot-profile .vertical-div {
	height: 600px;
	position: absolute;
	border: 2px solid #bdbdbd;
	top: 100px;
	left: 300px;
}

.left-foot-profile .profile-dot {
	position: absolute;
	height: 0.8vw;
	width: 0.8vw;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-size: 1.6vw;
	color: white;
	cursor: pointer;
	padding: 1.3vw;
	border-radius: 50px;
	background-color: var(--primary-green);
	box-shadow: 0 0 20px 3px var(--primary-green);
}

.left-foot-profile .arch-type {
	position: absolute;
	top: 70%;
	left: 27%;
	font-size: 1.5vw;
	color: #30b02d;
	font-weight: bold;
}
