.capture-image-btn {
	width: fit-content;
	border: none;
	padding: 1rem 2rem;
	border-radius: 10px;
	background-color: rgb(147, 209, 155);
	cursor: pointer;
	align-self: center;
	margin-top: 5%;
}

.capture-image-btn:hover {
	background-color: black;
	color: white;
	transition: all ease 0.2s;
}

.resultant-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 1rem;
	justify-content: center;
}
