.PHQ-intro {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.PHQ-intro .phq-intro-back-img {
	position: absolute;
	background-image: url("../../../assets/Foot-Image-5.png");
	top: 0;
	right: 150px;
	opacity: 0.3;
	background-size: 100% 100%;
	height: 900px;
	width: 700px;
}
