.pain-point {
	height: 50px;
	width: 50px;
	cursor: pointer;
	border-radius: 50px;
	box-shadow: rgba(255, 14, 14, 0.35) 0px 5px 15px;
}
/* 
.pain-point-item {
	position: absolute;
	height: 12px;
	width: 12px;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-size: 1.6vw;
	color: white;
	cursor: pointer;
	padding: 20px;
	border-radius: 50px;
	background-color: var(--primary-green);
	box-shadow: 0 0 20px 3px var(--primary-green);
} */

/* .pain-point-item-selected {
	position: absolute;
	height: 12px;
	width: 12px;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-size: 1.5rem;
	color: white;
	cursor: pointer;
	padding: 20px;
	border-radius: 50px;
	background-color: var(--primary-green);
	box-shadow: 0 0 20px 3px var(--primary-green);
	background-color: red;
	box-shadow: 0 0 20px 3px red;
} */

/* .responsive-image {
	height: 100%;
	width: auto;
	object-fit: contain;
}
.image-container {
	height: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
} */

.right-pain-point-1 {
	top: 28%;
	left: 46%;
}

.right-pain-point-2 {
	top: 60%;
	left: 55%;
}

.right-pain-point-3 {
	top: 85%;
	left: 50%;
}

.right-pain-point-4 {
	top: 35%;
	left: 90%;
}

.right-pain-point-7 {
	top: 35%;
	left: 60%;
}

.right-pain-point-5 {
	top: 73%;
	left: 65%;
}

.right-pain-point-6 {
	top: 64%;
	left: 16%;
}

.right-pain-point-8 {
	top: 55%;
	left: 38%;
}
