.scan-report .scan-image {
	background-image: url(../../../assets/Scan.jpg);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	object-fit: contain;
}
.scan-report .basic-report {
	position: absolute; /* Positions the element relative to the container */
	left: 50%;
	transform: translateX(-50%); /* Offsets the element by half of its own size */
	width: auto;
	background-color: #021434;
	color: white;
	padding: 0.3rem 1rem;
	border-radius: 50px;
	font-weight: bold;
	bottom: 5%;
	text-align: center;
	font-size: 1.3vw;
}

.scan-report .left-heel-width {
	position: absolute;
	top: 67%;
	left: 13.5%;
	font-size: 1.5vw;
	font-weight: bold;
}

.scan-report .left-length {
	position: absolute;
	top: 32%;
	left: 7%;
	font-weight: bold;
	font-size: 1.5vw;
}

.scan-report .left-width {
	position: absolute;
	top: 15%;
	left: 12.5%;
	font-weight: bold;
	font-size: 1.5vw;
}

.scan-report .right-width {
	font-weight: bold;
	font-size: 1.5vw;
	position: absolute;
	top: 15%;
	left: 32.5%;
}

.scan-report .right-heel-width {
	position: absolute;
	top: 67%;
	left: 31.5%;
	font-size: 1.5vw;
	font-weight: bold;
}

.scan-report .right-length {
	font-weight: bold;
	font-size: 1.5vw;
	position: absolute;
	top: 32%;
	left: 25%;
}

.scan-report .left-toe-type {
	position: absolute;
	font-weight: bold;
	font-size: 1.5vw;
	top: 10%;
	left: 8%;
	width: 17%;
	text-align: center;
}

.scan-report .right-toe-type {
	position: absolute;
	font-weight: bold;
	font-size: 1.5vw;
	top: 10%;
	left: 27.5%;
	width: 17%;
	text-align: center;
}

.scan-report .left-shoe-size {
	font-weight: bold;
	font-size: 1.5vw;
	position: absolute;
	top: 80%;
	left: 8%;
	width: 18%;
	text-align: center;
}

.scan-report .right-shoe-size {
	font-weight: bold;
	font-size: 1.5vw;
	position: absolute;
	top: 80%;
	left: 26%;
	width: 18%;
	text-align: center;
}
.scan-report .basic-details {
	display: inline-flex;
	position: absolute;
	top: 3%;
	right: 1%;
	font-size: 1.5vw;
	background-color: #66ae49;
	color: white;
	font-weight: bold;
	padding: 0.5rem 1rem;
	border-radius: 10px;
}

.scan-report .basic-details .name {
	padding-right: 0.5rem;
	border-right: 4px solid white;
}

.scan-report .basic-details .gender {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	border-right: 4px solid white;
}

.orientation {
	color: var(--secondary);
	writing-mode: tb-rl;
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.scan-report .left-arch-type {
	font-weight: bold;
	font-size: 1.5vw;
	position: absolute;
	top: 36%;
	left: 80%;
	width: 17%;
	text-align: center;
}

.scan-report .right-arch-type {
	font-weight: bold;
	font-size: 1.5vw;
	position: absolute;
	top: 69%;
	left: 80%;
	width: 17%;
	text-align: center;
}

.scan-report .left-instep-height {
	position: absolute;
	font-weight: bold;
	font-size: 1.5vw;
	top: 24%;
	left: 66.5%;
}

.scan-report .right-instep-height {
	position: absolute;
	font-weight: bold;
	font-size: 1.5vw;
	top: 58%;
	left: 66.5%;
}

.scan-report .left-arch-height {
	position: absolute;
	font-weight: bold;
	font-size: 1.5vw;
	top: 48%;
	left: 58.5%;
}

.scan-report .right-arch-height {
	position: absolute;
	font-weight: bold;
	font-size: 1.5vw;
	top: 83%;
	left: 59.5%;
}

.right-bottom-qr {
	position: absolute;
	top: 78%;
	right: 5%;
	height: 12%;
	width: fit-content;
}

.scan-report .basic-details .age {
	padding-left: 0.5rem;
}
