.recommendations .product-details-cont {
	position: absolute;
	/* top: 100px; */
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
	width: 500px;
	font-size: 1.5rem;
	font-weight: bold;
	padding: 2rem;
	border-radius: 20px;
	left: 50%;
	top: 50%;
	border: 1px solid var(--primary-green);
	transform: translate(-50%, -50%);
}

.recommendations .product-price,
.recommendations .product-details {
	margin-top: 1rem;
}

.recommendations .product-price,
.recommendations .product-name {
	font-size: 2.5rem;
}

.recommendations .product-name,
.recommendations .product-details {
	border-bottom: 1px solid #6e6e6e;
	padding-bottom: 1rem;
}

.recommendations .product-cont {
	height: 300px;
	width: 300px;
	border: 5px solid var(--primary-green);
	border-radius: 10px;
	padding: 5px;
}

.recommendations .recom-back-img {
	position: absolute;
	background-image: url("../../../assets/Foot-Image-9.png");
	background-size: 100% 100%;
	background-position: center;
	height: 700px;
	width: 600px;
	top: 0px;
	right: 750px;
	opacity: 0.3;
	z-index: -1;
	margin-top: 0px;
}

.recommendations .product-cont img {
	height: 100%;
	width: 100%;
}
