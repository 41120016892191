.theme-radio {
	font-size: 1.2vw;
	font-weight: bold;
}

.theme-radio label {
	display: flex;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	margin-right: 0.375em;
	/* Accessible outline */
	/* Remove comment to use */
	/* &:focus-within {
		outline: .125em solid $primary-color;
   }
	*/
}
.theme-radio label input {
	position: absolute;
	left: -9999px;
	background-color: #d6d6e5;
}
.theme-radio label input:checked + span {
	background-color: #d6d6e5;
}
/* .theme-radio label input:checked + span:before {
	box-shadow: inset 0 0 0 0.4375em #295c00;
} */

.theme-radio .success label input:checked + span:before {
	/* box-shadow: inset 0 0 0 0.7375em #28a745; */
	border: 5px solid #28a745;
}

.theme-radio .failure label input:checked + span:before {
	/* box-shadow: inset 0 0 0 0.7375em rgb(207, 12, 12); */
	border: 5px solid tomato;
}

.theme-radio label span {
	display: flex;
	background-color: #d6d6e5;
	align-items: center;
	/* padding: 0.375em 0.75em 0.375em 0.375em; */
	border-radius: 10px;
	transition: 0.25s ease;
}
.theme-radio label span:hover {
	background-color: #d6d6e5;
}
.theme-radio label span:before {
	display: flex;
	flex-shrink: 0;
	content: "";
	background-color: #fff;
	width: 1.5em;
	height: 1.5em;
	border-radius: 50%;
	margin-right: 0.375em;
	transition: 0.25s ease;
	box-shadow: inset 0 0 0 0.125em #00005c;
}
