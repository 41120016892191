.image-container {
	display: flex;
	justify-content: center;
	align-items: center;
	// border: 1px solid red;
}
.right-foot-profile .profile-img {
	height: 28vw;
	width: auto;
	object-fit: contain;
}

.pain-point-item-selected-profile {
	background-color: red !important;
	box-shadow: 0 0 20px 3px red !important;
}

.right-foot-profile .profile-3 {
	top: 50%;
	right: 15%;
}

.right-foot-profile .profile-2 {
	top: 50%;
	right: 33%;
}

.right-foot-profile .profile-1 {
	top: 75%;
	right: 43%;
}

.right-foot-profile .profile-4 {
	top: 20%;
	right: 16%;
}

.right-foot-profile .profile-7 {
	top: 12%;
	right: 33%;
}

.right-foot-profile .profile-8 {
	top: 38%;
	right: 48%;
}

.right-foot-profile .profile-6 {
	top: 74%;
	right: 59%;
}

.right-foot-profile .profile-dot {
	position: absolute;
	height: 0.8vw;
	width: 0.8vw;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-size: 1.6vw;
	color: white;
	cursor: pointer;
	padding: 1.3vw;
	border-radius: 50px;
	background-color: var(--primary-green);
	box-shadow: 0 0 20px 3px var(--primary-green);
}

.right-foot-profile .arch-type {
	position: absolute;
	top: 70%;
	right: 27%;
	font-size: 1.5vw;
	color: #30b02d;
	font-weight: bold;
}
