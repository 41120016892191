@import url("https://fonts.googleapis.com/css?family=Poppins:900i");

@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@1,700&family=Noto+Serif:wght@700&family=Open+Sans&family=Tilt+Prism&display=swap");

.landing {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;

	.landing-left {
		display: flex;
		flex-direction: column;
		position: relative;
		/* height: 100%; */
		padding: 0rem 0rem;
		align-items: center;
		justify-content: center;
	}

	.landing-left img {
		-webkit-transform: scaleX(-1);
		transform: scaleX(-1);
	}

	.landing-right {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.wrapper {
		display: flex;
		width: 40%;
	}

	.cta {
		display: flex;
		padding: 1% 15%;
		text-decoration: none;
		color: var(--secondary);
		font-family: "Poppins", sans-serif;
		font-weight: bold;
		background: var(--primary-green);
		transition: 1s;
		box-shadow: 6px 6px 0 black;
		transform: skewX(-15deg);
		transition: 0.5s;
		box-shadow: 10px 10px 0 var(--secondary);
		align-items: center;
	}
	.cta:focus {
		outline: none;
	}

	.cta span:nth-child(2) {
		transition: 0.5s;
		margin-right: 0px;
	}
	.cta span:nth-child(2) {
		transition: 0.5s;
		margin-right: 45px;
	}
	.start span {
		transform: skewX(15deg);
	}
	.start span:nth-child(2) {
		width: 0px;
		margin-left: 30px;
		position: relative;
		top: 12%;
	}
	/**************SVG****************/
	.start path.one {
		transition: 0.4s;
		transform: translateX(-60%);
	}
	.start path.two {
		transition: 0.5s;
		transform: translateX(-30%);
	}
	.start .cta path.three {
		animation: color_anim 1s infinite 0.2s;
	}
	.start .cta path.one {
		transform: translateX(0%);
		animation: color_anim 1s infinite 0.6s;
	}
	.start .cta path.two {
		transform: translateX(0%);
		animation: color_anim 1s infinite 0.4s;
	}

	.header .analyse-text {
		/** TEXT GRADIENT */
		color: #4caf50;
		background-image: -webkit-linear-gradient(45deg, #4caf50 0%, #3f51b5 100%);
		background-clip: text;
		-webkit-background-clip: text;
		text-fill-color: transparent;
		-webkit-text-fill-color: transparent;
	}

	/* SVG animations */
	@keyframes color_anim {
		0% {
			fill: white;
		}
		50% {
			fill: var(--secondary);
		}
		100% {
			fill: white;
		}
	}

	.header .title {
		background: linear-gradient(to right, #4caf50 0%, #01579b 100%);
		background-clip: text;
		-webkit-background-clip: text;
		font-family: "Noto Serif", serif;
		font-size: 5vw;
		text-fill-color: transparent;
		-webkit-text-fill-color: transparent;
		text-align: center;
		color: var(--secondary);
		-webkit-background-size: 425px 100%;
		-moz-background-size: 425px 100%;
		background-size: 425px 100%;
		-webkit-background-clip: text;
		-moz-background-clip: text;
		background-clip: text;
		-webkit-animation-name: shimmer;
		-moz-animation-name: shimmer;
		animation-name: shimmer;
		-webkit-animation-duration: 1.5s;
		-moz-animation-duration: 1.5s;
		animation-duration: 1.5s;
		-webkit-animation-iteration-count: infinite;
		-moz-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
		background-repeat: no-repeat;
		background-position: 0 0;
		background-color: var(--secondary);
	}

	@-moz-keyframes shimmer {
		0% {
			background-position: top left;
		}
		100% {
			background-position: top right;
		}
	}

	@-webkit-keyframes shimmer {
		0% {
			background-position: top left;
		}
		100% {
			background-position: top right;
		}
	}

	@-o-keyframes shimmer {
		0% {
			background-position: top left;
		}
		100% {
			background-position: top right;
		}
	}

	@keyframes shimmer {
		0% {
			background-position: top left;
		}
		100% {
			background-position: top right;
		}
	}
}
