.bottom-pointer-right {
	position: absolute;
	right: -20px;
	bottom: -20px;
	cursor: pointer;
	background-color: #60cf63;
	border-radius: 10px 0px;
	color: white;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 10%;
	width: 20%;
}

.bottom-pointer-left {
	position: absolute;
	left: -20px;
	bottom: -20px;
	cursor: pointer;
	background-color: #60cf63;
	border-radius: 0px 10px;
	color: white;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 10%;
	width: 20%;
}

.icon-3 svg {
	color: #60cf63 !important;
}

.step {
	width: 15px;
	height: 100%;
	transition: width 1s;
	border-radius: 5px;
}

.step_selected {
	width: 50px;
	min-height: 100px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}
