body {
	background: linear-gradient(-45deg, #f6fffd, #f6fffd, #f6fffd);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.header {
	font-size: 2.5vw;
}

.sub-header {
	font-size: 2vw;
}

.other-text-1 {
	font-size: 1.5vw;
}

.other-text-2 {
	font-size: 1.2vw;
}

.annimation-back {
	height: 100vh;
	width: 100%;
	background-color: #ffffff99;
	filter: blur(10px);
	position: absolute;
	/* z-index: 990; */
}

.body-content {
	z-index: 999;
	position: relative;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	border-radius: 0px;
	background-color: #e7e7e7;
	border: 1px solid #cacaca;
}
::-webkit-scrollbar-thumb {
	border-radius: 0px;
	background-color: #60cf63;
}

.preloader {
	align-items: center;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	transition: opacity 0.2s linear;
	width: 100%;
	z-index: 99999999;
	opacity: 1;
	transform: opacity 1s linear;
}
