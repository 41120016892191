.app-bar {
}

.group-logo {
	display: inline-flex;
	gap: 30px;
	flex: 1;
	justify-content: flex-start;
}

.group-logo img {
	height: 80px;
	max-width: 200px;
}

.nav-links {
	display: inline-flex;
	gap: 30px;
	flex: 1;
	justify-content: flex-end;
}

.icon {
	font-size: 30px;
	color: var(--secondary) !important;
}

.app-bar button {
	font-family: inherit !important;
	font-size: 1.3rem;
	text-transform: none !important;
	color: #5bb53c !important;
	font-weight: bold;
}

.nav-links img {
	width: 150px;
	height: 70px;
}
