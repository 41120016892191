.identifying-information {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
/* 
.identifying-back-img {
	position: absolute;
	background-image: url("../../../assets/Foot-Image-4.png");
	top: 0;
	left: 450px;
	opacity: 0.3;
	background-size: 100% 100%;
	height: 1200px;
	width: 1100px;
} */
