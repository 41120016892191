@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: Arial, Helvetica, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #4e4e4e;
}

:root {
	--primary-green: #60cf63;
	--secondary: #1f2149;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.mgr-10 {
	margin-right: 11px;
}

.mgb-3rem {
	margin-bottom: 3rem;
}

.gradient-header {
	font-size: 3rem;
	background: linear-gradient(to right, #4caf50 0%, #01579b 100%);
	background-clip: text;
	-webkit-background-clip: text;
	font-family: "Noto Serif", serif;
	text-fill-color: transparent;
	-webkit-text-fill-color: transparent;
}

.red-alert {
	color: red;
	font-weight: bold;
}

.button-theme-color {
	background-color: var(--secondary) !important;
	color: var(--primary-green) !important;
}

/* .theme-cont {
	height: calc(100vh - 140px);
	padding: 4rem;
}

.theme-sub-cont {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	padding-top: 2rem;
} */

.question {
	color: black;
	margin-bottom: 2rem;
	font-size: 2rem;
	font-weight: bold;
	letter-spacing: 2px;
}

.question-icon {
	color: #263238;
}

.text-center {
	text-align: center !important;
	width: 100%;
}

.pain-point {
	height: 50px;
	width: 50px;
	cursor: pointer;
	border-radius: 50px;
	box-shadow: rgba(255, 14, 14, 0.35) 0px 5px 15px;
}

.pain-point-item {
	position: absolute;
	height: 12px;
	width: 12px;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-size: 1.9rem;
	color: white;
	cursor: pointer;
	padding: 20px;
	border-radius: 50px;
	background-color: var(--primary-green);
	box-shadow: 0 0 20px 3px var(--primary-green);
}

.pain-point-item-selected {
	position: absolute;
	height: 12px;
	width: 12px;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-size: 1.9rem;
	color: white;
	cursor: pointer;
	padding: 20px;
	border-radius: 50px;
	background-color: var(--primary-green);
	box-shadow: 0 0 20px 3px var(--primary-green);
	background-color: red;
	box-shadow: 0 0 20px 3px red;
}
.flex-center {
	display: flex !important;
	flex: 1 !important;
	align-items: center !important;
	justify-content: center !important;
}

.cross-button {
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
	padding: 2rem;
}

.PHQ-core-back-img {
	position: absolute;
	background-image: url("./assets/Foot-Image-8.png");
	top: -30px;
	right: 150px;
	z-index: -1;
	opacity: 0.3;
	background-size: 100% 100%;
	height: 900px;
	width: 800px;
}

.PHQ-core-back-img-left {
	position: absolute;
	background-image: url("./assets/Foot-Image-8.png");
	top: -30px;
	left: 150px;
	z-index: -1;
	opacity: 0.3;
	background-size: 100% 100%;
	height: 900px;
	width: 800px;
	transform: scaleX(-1);
}

.body-center {
	min-height: 80vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.tryAgain {
	background-color: #4caf50 !important;
	color: white !important;
	padding-left: 10px !important;
	padding-right: 10px !important;
}
